import React from 'react';

import styled from 'styled-components';

import { FormattedMessage } from '~/techstyle-shared/react-intl';

import Logo from '../Logo';

const RedirectWrapper = styled.div`
  padding: 50px;
  text-align: center;
`;

const RedirectMessage = styled.p`
  margin: 1em 0;
`;

export default function LoadingRedirect() {
  return (
    <RedirectWrapper>
      <Logo width={200} />
      <RedirectMessage>
        <FormattedMessage
          id="global_cta.login_redirect_loading"
          defaultMessage="Redirecting you, please wait…"
        />
      </RedirectMessage>
    </RedirectWrapper>
  );
}
