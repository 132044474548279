import React from 'react';

import config from 'config';
import styled, { css } from 'styled-components';

import useGender from '~/shared/utils/useGender';
import useMembershipPrice from '~/shared/utils/useMembershipPrice';
import { AssetContainer } from '~/techstyle-shared/react-assets';
import { Button, mobile } from '~/techstyle-shared/react-components';
import { FormattedMessage } from '~/techstyle-shared/react-intl';

import useSiteSection, { SiteSection } from '../../utils/useSiteSection';

const verticalSpacing = css`
  margin-bottom: ${({ theme }) => theme.space('m')};
`;
const mobileHide = css`
  ${mobile`display:none;`}
`;

const Wrapper = styled.div`
  font-size: 14px;
  box-sizing: border-box;
`;

const BenefitsList = styled.ul`
  ${mobileHide}
`;
const BenefitsListItem = styled.li`
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 450;
  strong {
    font-weight: 600;
  }
  .medium {
    font-weight: bold;
  }
`;

const AssetDetails = styled.div``;
const AssetH3 = styled.h3`
  font-size: 16px;
  font-weight: 700;
  ${verticalSpacing}
  ${mobileHide}
`;

const HtmlAssetContainer = styled(AssetContainer)`
  -webkit-font-smoothing: antialiased;
  [data-asset-html] p {
    margin: 0.25em auto;
    ${mobile`
      font-size: 4vw;
    `}
  }
`;

const MarginSection = styled.div`
  ${verticalSpacing}
`;
const GetStartedButton = styled(Button).attrs({
  variant: 'secondary',
})`
  margin-bottom: ${({ theme }) => theme.space('s')};
  width: 100%;
  padding: 0 ${({ theme }) => theme.space('xl')};
  height: 50px;
  ${mobileHide}
`;

export default function LoginAsset() {
  const membershipPrice = useMembershipPrice({ asCurrency: true });
  const currentSiteSection = useSiteSection();
  const siteSectionValueData = currentSiteSection.sessionDetailValue;
  const { gender } = useGender();
  const quizUrl = config.get('public.brand.quizUrl')[gender];
  const womensLoginBanner = config.get('public.brand.loginWomensBanner');
  const mensLoginBanner = config.get('public.brand.loginMensBanner');
  const loginBannerAsset =
    siteSectionValueData === SiteSection.MEN
      ? mensLoginBanner
      : womensLoginBanner;

  return (
    <>
      <Wrapper>
        <AssetDetails data-autotag="vip_perks_text">
          <MarginSection>
            <HtmlAssetContainer
              name={loginBannerAsset}
              aspectRatio="3:1"
              background
            />
          </MarginSection>
          <MarginSection>
            <GetStartedButton
              tagName="a"
              data-autotag="login_page_get_started_desktop"
              href={quizUrl}
            >
              <FormattedMessage
                id="global_cta.get_started"
                defaultMessage="Get Started"
              />
            </GetStartedButton>
          </MarginSection>
          <AssetH3>
            <FormattedMessage
              id="site_login.join_benefits"
              defaultMessage="Benefits Of Joining Flexible VIP Membership"
            />
          </AssetH3>
          <BenefitsList>
            <BenefitsListItem>
              <FormattedMessage
                id="site_login.style_benefits"
                defaultMessage="<strong>Style</strong> - New collections featuring fresh, on-trend outfits launch each month.
              "
              />
            </BenefitsListItem>
            <BenefitsListItem>
              <FormattedMessage
                id="site_login.function_benefits"
                defaultMessage="<strong>Function</strong> - Our garments flatter your form while keeping you comfortable."
              />
            </BenefitsListItem>
            <BenefitsListItem>
              <FormattedMessage
                id="site_login.quality_benefits"
                defaultMessage="<strong>Quality</strong> - Cutting-edge design and high-performance fabrics offer amazing fit."
              />
            </BenefitsListItem>
            <BenefitsListItem>
              <FormattedMessage
                id="site_login.value_benefits"
                defaultMessage="<strong>Value</strong> - Get high-caliber clothing for less. Most complete outfits are only {membershipPrice}."
                values={{ membershipPrice }}
              />
            </BenefitsListItem>
            <BenefitsListItem>
              <FormattedMessage
                id="site_login.convenience_benefits"
                defaultMessage="<strong>Convenience</strong> - Enjoy personalized monthly picks, free shipping* and free returns"
              />
            </BenefitsListItem>
            <BenefitsListItem>
              <FormattedMessage
                id="site_login.flexibility_benefits"
                defaultMessage="<strong>Flexibility</strong> - There's never any obligation to buy. Just skip the month by the 5th."
              />
            </BenefitsListItem>
          </BenefitsList>
        </AssetDetails>
      </Wrapper>
    </>
  );
}

LoginAsset.propTypes = {};
