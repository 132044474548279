import React from 'react';

import config from 'config';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';

import {
  FableticsLogo,
  YittyLogo,
  FableticsOutletLogo,
  SavageLogo,
} from '~/techstyle-shared/react-components';

const logos = { FableticsLogo, YittyLogo, FableticsOutletLogo, SavageLogo };
export default function Logo({
  brandName = config.get('public.brand.name'),
  href,
  ...rest
}) {
  const brand = capitalize(brandName);
  const LogoImage = href?.includes('/outlet')
    ? FableticsOutletLogo
    : logos[`${brand}Logo`] || FableticsLogo;
  return <LogoImage {...rest} />;
}

Logo.propTypes = {
  brandName: PropTypes.oneOf(['fabletics', 'yitty']),
  href: PropTypes.string,
};
