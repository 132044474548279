import { useMembership } from '~/techstyle-shared/react-accounts';
import { useCurrency } from '~/techstyle-shared/react-intl';
import { useDomain } from '~/techstyle-shared/redux-core';

import featureNames from '../constants/featureFlags';

import useHasFeature from './useHasFeature';

export default function useMembershipPrice({ asCurrency }) {
  const { formatCurrency } = useCurrency();
  const { price } = useMembership();
  const { tld } = useDomain();
  const membershipDefaultPrice5995 = useHasFeature(
    featureNames.MEMBERSHIP_5995_PRICE
  );

  // if no `price` is returned from useMembership(), set default price to 59.95.
  // this supports the membership price increase for guests/leads.
  // in the future, would be best to have a fallback reliant on a stores
  // default price.
  const centValue = tld === '.co.uk' ? '99' : '95';
  const priceValue =
    price ||
    (membershipDefaultPrice5995 ? `59.${centValue}` : `54.${centValue}`);

  return asCurrency ? formatCurrency(priceValue) : priceValue;
}
