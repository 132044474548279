import React, { useEffect, useState } from 'react';

import config from 'config';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import {
  allComponents,
  registerBuilderComponents,
  registerBuilderMenu,
} from '~/builder/utils/registerBuilderComponents';
import DefaultLayout from '~/layout/components/DefaultLayout';
import LoginAsset from '~/layout/components/LoginAsset';
import LoginSlidingPanels from '~/login/components/LoginSlidingPanels';
import UsernameInputField from '~/login/components/UsernameInputField';
import {
  AuthenticationErrorTypes,
  AUTOLOGIN_FAILED,
} from '~/login/utils/LoginErrorMessages';
import useLoginRedirect from '~/login/utils/useLoginRedirect';
import LoadingRedirect from '~/shared/components/LoadingRedirect';
import usePersistMpid from '~/shared/utils/usePersistMpid';
import {
  desktop,
  Heading,
  mobile,
  Section,
} from '~/techstyle-shared/react-components';
import {
  FormattedMessage,
  defineMessages,
} from '~/techstyle-shared/react-intl';
import { useSession } from '~/techstyle-shared/redux-core';

import createPage from '../registry';

const dynamicDefineMessages = defineMessages;
const seo = dynamicDefineMessages({
  title: {
    id: 'site_seo_title.account_login',
    defaultMessage: `Login & Registration | ${config.get(
      'public.brand.label'
    )}`,
  },
  description: {
    id: 'site_seo_description.account_login',
    defaultMessage:
      'Login or request a password reset. Not a member yet? Sign up and receive great deals!',
  },
});

const loginSlideUpPanelIsShown = config.get(
  'public.brand.loginSlideUpPanelIsShown'
);
const loggedInRedirectUrl = config.get('public.loggedInRedirectUrl');

const Wrapper = styled.div`
  text-align: left;
  display: flex;

  ${desktop`
    max-width: 860px;
    margin: 50px auto 150px;
    justify-content: space-between;
  `}

  ${mobile`
    flex-wrap: wrap;
  `}
`;

const BecomeAMemberSection = styled(Section)`
  font-size: 14px;
  flex: 0 1 50%;
  order: 2;
  font-weight: 600;
  box-sizing: content-box;

  ${mobile`
    width: 100%;
    flex-grow: 1;
    flex-basis: 100%;
    order: 1;
  `}
`;

const BecomeAMemberHeader = styled.div`
  ${desktop`
    padding-left: 50px;
    padding-right: 50px;
  `}

  ${mobile`
    display: none;
  `}
`;

const AssetWrapper = styled.div`
  ${desktop`
    padding-left: 50px;
    padding-right: 50px;
    border-left: 2px solid ${({ theme }) => theme.colors.loginAssetBorder};
  `}

  ${mobile`
    padding: 5vw 5vw 0;
  `}
`;

const BecomeAMemberHeading = styled(Heading)`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const SpacedParagraph = styled.p`
  margin-bottom: ${({ theme }) => theme.space()};
`;

const LoginPageSlidingPanels = styled(LoginSlidingPanels)`
  ${desktop`
    ${LoginSlidingPanels.SlidingPanel} {
      padding: 0px 50px;
      position: relative;
      ${UsernameInputField.BackButtonContainer} {
        /* Because the BackButtonContainer has position: absolute on it, we need to
           position the back button absolutely in relation to the UsernameInputField */
        top: 15px;
      }
    }

    ${({ loginSlideUpPanelIsShown }) =>
      !loginSlideUpPanelIsShown &&
      css`
        ${LoginSlidingPanels.SignUpFooter} {
          display: none;
        }
      `}
  `}
`;

function LoginPage() {
  const performLoginRedirect = useLoginRedirect();
  const { query } = useRouter();
  // Note: alError has a value of 2 when the user tries to autologin with an email that doesn't exist.
  const { alError } = query;

  // For passwordless login, the reduxExtension handles redirection after a reload (e.g., the user is already logged
  //  in before reaching this component).
  // If the user logs in with a password on the <UsernameInputField /> component, the performLoginRedirect() is called from the UsernameInputField,
  // meaning this code is, again, never reached.
  // While it may seem redundant, keeping this redirection logic ensures additional safety and potentially backwards compatibility.
  const { isLoggedIn } = useSession();
  const [isInitiallyLoggedIn] = useState(isLoggedIn);
  useEffect(() => {
    if (isInitiallyLoggedIn) {
      performLoginRedirect();
    }
  }, [isInitiallyLoggedIn, performLoginRedirect]);

  // if mpid is in localStorage, append it to the url
  usePersistMpid();

  // Using this flag to unmount the site layout and rendering a "please wait"
  // message instead is a data fetching and performance optimization: after
  // logging in, the layout wants to render the logged in content (which
  // requires fetching user data from various endpoints), but we're about to
  // redirect the user anyway - potentially to a non-React page - so it would be
  // a waste of time and network resources. So we set this flag upon login and
  // use it to stop rendering the site layout (and thus the components fetching
  // data) while we wait for the redirect to happen.
  const pageWillUnmount = useSelector((state) => state.app.pageWillUnmount);

  return (
    <>
      {pageWillUnmount ? (
        <LoadingRedirect />
      ) : (
        <DefaultLayout title={seo.title} description={seo.description}>
          <Wrapper data-autotag="login_container">
            <LoginPageSlidingPanels
              onLoginSuccess={performLoginRedirect}
              loginSlideUpPanelIsShown={loginSlideUpPanelIsShown}
              alError={
                alError === AUTOLOGIN_FAILED &&
                AuthenticationErrorTypes.AUTOLOGIN_FAILED
              }
            />
            <BecomeAMemberSection>
              <BecomeAMemberHeader>
                <BecomeAMemberHeading>
                  <FormattedMessage
                    id="site_login.not_a_member"
                    defaultMessage="Not a Member?"
                  />
                </BecomeAMemberHeading>
                <SpacedParagraph>
                  <FormattedMessage
                    id="site_login.sign_up"
                    defaultMessage="Sign up for flexible VIP membership for exclusive access to Lizzo’s shapewear line."
                  />
                </SpacedParagraph>
              </BecomeAMemberHeader>
              <AssetWrapper>
                <LoginAsset />
              </AssetWrapper>
            </BecomeAMemberSection>
          </Wrapper>
        </DefaultLayout>
      )}
    </>
  );
}

registerBuilderComponents(allComponents);
registerBuilderMenu();

export default createPage(LoginPage, {
  loggedInRedirectUrl,
  marketingInfo: {
    segmentCategory: 'strapi',
  },
  prefetchAssets: [
    'login_image_offer',
    'quiz',
    config.get('public.brand.loginMensBanner'),
    config.get('public.brand.loginWomensBanner'),
  ],
  resourceBundles: ['quiz', 'site_login'],
});
