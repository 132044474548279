import { useCallback } from 'react';

import config from 'config';
import { useRouter } from 'next/router';

import { getSecondarySite } from '~/shared/utils/useSecondarySite';

/**
 * Return a function that, when called, will perform the post-login redirect to
 * the correct URL, accounting for query params like `returnaction` and
 * `passthru`. If those are not specified, it falls back to the configured
 * `public.loggedInRedirectUrl` value.
 *
 * useLoginRedirect:
 *  - Handles post-login redirection
 *  - Considers secondary sites and passthrough parameters
 *  - Does set window.location.href to the redirect URL
 */
export default function useLoginRedirect() {
  const { asPath } = useRouter();

  return useCallback(() => {
    let loggedInRedirectUrl = config.get('public.loggedInRedirectUrl');
    const secondarySite = getSecondarySite(asPath);
    let passThruDestination = '';

    // This is to support legacy passthru parameters
    // ex: https://www.fabletics.com/login#passthru=friends.main%26usa%3D0%26frommobileapp%3Dfalse%26enable%5Frma%3D0%26aus%3D0
    if (asPath && asPath.includes('#passthru=') && asPath.includes('.main')) {
      passThruDestination = asPath.split('#passthru=')[1]?.split('.')[0];

      if (passThruDestination && passThruDestination !== '') {
        loggedInRedirectUrl += `${passThruDestination}`;
      }
    }

    if (secondarySite) {
      loggedInRedirectUrl = `/${secondarySite}${loggedInRedirectUrl}`;
    }

    window.location.href = loggedInRedirectUrl;
  }, [asPath]);
}
